.section {
  margin: 24px;

  .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
    color: var(--accent-1);
  }

  .description {
    font-size: 13px;
    font-family: "Pretendard";
    font-weight: 500;
    color: var(--accent-3);
  }

  .popup {
    .title {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 600;
    }

    .row {
      margin-top: 12px;
      display: flex;
      gap: 12px;
    }
  }

  .form {
    display: flex;
    gap: 12px;

    .item {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;

      .title {
        margin: 12px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }

      .label {
        font-size: 14px;
        font-weight: 500;
        padding: 12px;
        border-radius: 4px;
        border: var(--border-default);
        background-color: var(--component-color);
      }
    }
  }
}
