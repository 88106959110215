.search_container {
  height: 56px;
  display: flex;
  border-bottom: var(--border-default);
  align-items: center;

  .title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 0 24px;
    color: var(--accent-1);
  }

  .search {
    margin: 0 24px;
    outline: none;
    border: none;
    border-radius: 8px;
    background-color: var(--component-color);
    padding: 8px 18px;
    flex: 1 1 0;
  }
}

.section {
  height: calc(100vh - 56px);
  overflow: auto;

  .settings_container {
    padding: 24px;
    background-color: var(--background-color);
    border-radius: 8px;

    max-width: 800px;
    margin: 0 auto;

    .container_title {
      font-size: 16px;
      color: var(--accent-2);
      font-weight: 600;
      margin-bottom: 24px;
    }

    .container_subtitle {
      font-size: 12px;
      color: var(--accent-2);
      font-weight: 600;
      margin-bottom: 8px;

      .required {
        padding-left: 1px;
        color: var(--alert-c1);
      }
    }

    .profile_upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .profile_boxed {
        max-width: 160px;
        width: 160px;
        max-height: 160px;
        height: 160px;
        overflow: hidden;
        position: relative;
        border-radius: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .setting_item {
    margin: 16px 0;
    display: flex;
    .icon {
      fill: var(--accent-1);
      width: 40px;
      display: flex;
      align-items: center;
    }

    .info {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;

      .label {
        font-size: 13px;
        color: var(--accent-2);
        font-weight: 600;
        margin-bottom: 5px;
      }

      .description {
        font-size: 12px;
        color: var(--accent-4);
        font-weight: 600;
        margin-bottom: 5px;
      }
    }

    .controls {
      fill: var(--accent-1);
      display: flex;
      align-items: flex-end;
    }
  }
}
