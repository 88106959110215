

.user_info {
	display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
	.user_profile_img_container {
		--length: 100px;
		max-width: var(--length);
		width: var(--length);
		max-height: var(--length);
		height: var(--length);
		overflow: hidden;
		position: relative;
		border-radius: 100%;
		margin-bottom: 8px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.user_school {
		font-size: 12px;
		color: var(--accent-3);
	}
	.user_role {
		font-size: 12px;
		color: var(--accent-3);
		margin-bottom: 4px;
	}
	.user_name {
		font-size: 14px;
		font-weight: 700;
		color: var(--accent-1);
	}
}