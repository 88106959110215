.section {
    padding: 24px;
    height: calc(100vh - 56px);
    overflow-y: scroll;

    .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 24px;
        color: var(--accent-1);

    }

    .design_form {
        margin: 0 auto;
        max-width: 900px;
    }
}