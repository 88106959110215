.textarea_container {

    display: flex;
    flex-direction: column;

    flex: 1 1 0;

    .textarea {
        resize: vertical;
        font-family: 'Pretendard';

        background-color: inherit;
        color: var(--accent-1);
        padding: 12px 14px;
        font-size: 14px;
        outline: none;
        border: var(--border-default);
        width: 100%;
    }

    .textarea::-webkit-scrollbar {
        background-color: transparent;
        width: 16px
    }

    .textarea::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 6px solid var(--component-color);
    }

    .textarea::-webkit-scrollbar-button {
        display: none
    }


    .textarea.invaild {
        border-color: var(--alert-c1);
    }

    .label {
        font-size: 12px;
        font-family: 'Pretendard';
        font-weight: 500;
        margin-bottom: 5px;
        color: var(--accent-1);


        .required {
            padding-left: 1px;
            color: var(--alert-c1);
        }
    }

}


.textarea_container.flat {

    display: flex;
    flex-direction: column;

    flex: 1 1 0;

    .label {
        font-size: 12px;
        color: var(--accent-2);
        font-weight: 600;

        margin-bottom: 5px;

        .required {
            padding-left: 1px;
            color: var(--alert-c1);
        }

    }

    .textarea {
        margin-top: 5px;
        outline: none;
        border: none;
        border-radius: 8px;
        background-color: var(--component-color);
        width: 100%;

    }

    .textarea.invaild {
        background-color: var(--alert-c1);
    }





}