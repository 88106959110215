.viewer {
  .header {
    z-index: 12;
    position: sticky;
    top: 0;

    background-color: var(--background-color);

    border-bottom: var(--border-default);
    font-size: 14px;
    font-weight: 600;

    .days {
      display: flex;
      .day {
        padding: 3px 0px;
        font-weight: 500;
        flex: 1 1 0;
        text-align: center;

        display: flex;
        flex-direction: column;
      }

      .dayEvents {
        font-weight: 500;
        flex: 1 1 0;
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        transition: background-color 0.1s ease;

        .dayEvent {
          padding: 3px;
          border-radius: 8px;
        }
        .dayEvent:hover {
          background-color: var(--component-hover-color);
        }
      }
    }
  }

  .calendar {
    display: flex;
    position: relative;

    -ms-overflow-style: none;
    overflow-y: scroll;
    scrollbar-width: none;

    .time_labels {
      width: 80px;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: 600;
      text-align: right;
      color: rgb(75, 75, 75);

      .label {
        min-height: 80px;
        line-height: 0px;
      }

      .label:nth-child(1) {
        line-height: 12px;
      }
    }

    .current_time {
      z-index: 11;
      right: 0;
      position: absolute;
      display: flex;
      width: 100%;

      .time {
        font-size: 12px;
        font-weight: 600;
        line-height: 0;
        min-width: 80px;
        max-width: 80px;
        text-align: right;
        color: red;
      }

      .indicator {
        flex: 1 1 0;
        height: 1px;
        background-color: red;
      }
    }

    .grid {
      flex: 7 1 0;
      display: flex;
      z-index: 1;

      .column {
        position: relative;
        flex: 1 1 120px;
        border-left: var(--border-default);
        .row_grid {
          z-index: 1;

          .block {
            min-height: 80px;
            border-bottom: var(--border-default);
          }

          .block:last-child {
            border-bottom: none;
          }
        }

        .event_container {
          z-index: 10;
          position: absolute;
          top: 0px;
          width: 100%;
          height: 0px;

          .event {
            margin: 0 4px;
            cursor: pointer;
            position: absolute;
            width: calc(100% - 8px);
            font-size: 12px;
            padding: 4px;
            border-radius: 4px;
            background-color: #cadeff;

            .title {
              word-break: keep-all;
              font-weight: 600;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .time {
              margin-top: 1px;
            }
          }

          .schoolCalendar {
            background-color: #b7c6dd;
          }
          .schoolCalendarTimetable {
            background-color: #e3dede;
          }
          .myCalendar {
            background-color: #feee7d;
          }
          .enrollments {
            background-color: #84b1ed;
          }
          .mentorings {
            background-color: #b4d085;
          }
        }

        .row_function {
          position: absolute;
          top: 0;
          z-index: 9;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;

          .block {
            min-height: 80px;
          }
        }
      }
    }

    .editor_background {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      z-index: 200;
      width: 100vw;
    }

    .editor_container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      z-index: 2022;
      position: fixed;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      background-color: var(--background-color);
      width: 800px;
      height: 500px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 4px;

      .title {
        font-size: 16px;
        font-weight: 600;
      }

      .content::-webkit-scrollbar {
        display: none;
      }

      .content {
        flex: 1 1 0;
        scrollbar-width: none;
        display: flex;
        flex-direction: column;
        gap: 12px;

        input {
          padding: 6px 8px;
          border-radius: 4px;
        }

        input:disabled {
          cursor: not-allowed;
        }

        textarea:disabled {
          cursor: not-allowed;
        }

        .title_input {
          font-size: 18px;
          font-weight: 600;
          border: none;
          outline: none;
        }

        select {
          outline: none;
          border: none;
          background-color: var(--component-color);
          padding: 6px;
          border-radius: 4px;
          width: 100%;
        }

        .date {
        }

        .room {
        }

        .time {
          display: flex;
          gap: 12px;
          align-items: center;

          input[type="time"] {
            outline: none;
            border: none;
            background-color: var(--component-color);
            padding: 6px;
            border-radius: 4px;
            width: 100%;
          }
        }

        .other {
          display: flex;
          flex-direction: column;

          label {
            font-size: 12px;
            font-weight: 600;
            padding-bottom: 4px;
          }

          textarea {
            resize: vertical;
            outline: none;
            font-family: "Pretendard";
            border: none;
            background-color: var(--component-color);
            padding: 6px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.calendar_container::-webkit-scrollbar {
  display: none;
}

.isMounted {
  animation: fadein 0.05s ease-in;
  -webkit-animation: fadein 0.05s ease-in; /* Safari and Chrome */
}

.isUnmounted {
  animation: fadeout 0.05s ease-in;
  -webkit-animation: fadeout 0.05s ease-in; /* Safari and Chrome */
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
