@media print {
    .search {
        display: none !important;
    }

    .section {
        height: 100% !important;
        display: block !important;
        padding: none;
    }

    @page {
        margin: 10mm 4mm 10mm 4mm;
    }
}

.section::-webkit-scrollbar {
    display: none;
}

.section {
    height: calc(100vh - 56px);
    padding: 24px;
    color: var(--accent-1);
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    .title {
        font-weight: 600;
    }


    & > .search {
        min-height: 100px;
    }

    .search {
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 6px;

        @media (min-width: 769px) {
            width: 100%;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            width: 100%;

            & .select {
                width: 100%;
            }

            & .search {
                flex-direction: row;
            }
        }

        .label {
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;

            @media (max-width: 768px) {
                width: 100%;
            }
        }

    }
}


.selectGrade {
    @media (max-width: 768px) {
        max-width: 100% !important;
    }
}
