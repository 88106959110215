@keyframes sidebar_sub_link-openAnimation {
    0% {

        opacity: 0;
        display: none;

    }

    20% {

        opacity: 1;
        display: block;


    }
}

@media print {
    .nav_container {
        display: none !important;
    }

    @page {
        margin: 0;
        page-break-after: always;
    }
}

@media (max-width: 800px) {
    .nav_container {
        position: absolute;
        z-index: 2000;
        border: none !important;
        background-color: var(--background-color) !important;
        width: 100vw !important;
        // display: none !important;
    }

    .nav_container.open {
        max-width: 100vw !important;
    }

    .nav_container.close {
        height: 56px;
    }
}



.nav_container {
    user-select: none;
    border-right: var(--border-default);
    background-color: var(--component-color);
    color: var(--accent-1);
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;
    height: 100vh;

    .nav_logo {
        display: flex;
        align-items: center;
        height: 56px;
        min-height: 56px;
        padding: 8px;
        border-bottom: var(--border-default);
        word-break: keep-all;

        .icon {
            min-width: 24px;
            cursor: pointer;
            margin: 0 8px;
            fill: var(--accent-1);

        }

        .logo {
            font-family: 'Pretendard';
            font-weight: 500;
            font-size: 15px;
            letter-spacing: -0.25px;
            flex: 1 1 0;
            padding: 4px 8px;
            cursor: pointer;
            word-break: keep-all;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .caret {
            cursor: pointer;
            min-width: 16px;
            width: 16px;
            fill: var(--accent-1);
            flex-basis: 16px;
        }


    }

    .search_container {
        height: 52px;
        display: flex;


        .search {
            // background-color: aliceblue;

            margin: 8px;
            display: flex;
            flex: 1 1 0;

            align-items: center;

            .search_input {
                width: 100%;
                background-color: transparent;
                flex: 1 1 0;
                border: none;
                outline: none;

            }

            .icon {
                height: 24px;
                min-height: 24px;
                width: 24px;
                min-width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 8px;


                svg {
                    fill: var(--accent-4)
                }
            }
        }
    }

    .nav_links::-webkit-scrollbar {
        display: none;
    }

    .nav_links {
        flex: 1 1 0;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        .nav_link_container {
            display: flex;
            flex-direction: column;

            .nav_link {
                display: flex;
                margin: 4px 8px;
                padding: 6px 0;
                align-items: center;
                cursor: pointer;

                border-radius: 8px;

                .icon {
                    min-height: 24px;
                    height: 24px;
                    min-width: 24px;
                    width: 24px;
                    margin: 0 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {


                        fill: var(--accent-4)
                    }

                }

                .name {
                    word-break: keep-all;
                    white-space: nowrap;
                    font-weight: 600;
                    font-family: 'Pretendard';
                    width: 100%;
                    font-size: 13px;
                    flex: 1 1 0;
                    color: var(--accent-4)
                }
            }

            .nav_link:hover {
                background-color: var(--component-hover-color);
            }

            .sub_links {
                max-height: 0;
                display: none;
                position: relative;
            }



            .sub_link_container {
                opacity: 0;
                height: 0;

                .sub_link:hover {
                    background-color: var(--component-hover-color);
                }



                .sub_link {

                    display: flex;
                    margin: 2px 14px 2px 36px;
                    padding: 6px 6px;
                    flex: 1 1 0;
                    gap: 12px;
                    align-items: center;
                    cursor: pointer;

                    border-radius: 8px;

                    .icon {
                        display: flex;
                        min-height: 24px;
                        height: 24px;
                        min-width: 24px;
                        width: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {

                            fill: var(--accent-4)
                        }

                    }

                    .name {
                        word-break: keep-all;
                        white-space: nowrap;
                        flex: 1 1 0;
                        color: var(--accent-4);
                        font-weight: 600;
                        font-family: 'Pretendard';
                        width: 100%;
                        font-size: 13px;
                    }

                }


            }
        }

        // when active
        .nav_link_container.active {
            .nav_link {
                background-color: var(--canvas-color);
                box-shadow: var(--component-box-shadow);

                .icon {
                    svg {
                        fill: var(--primary-text-color);
                    }
                }

                .name {
                    color: var(--primary-text-color);
                }
            }


            .sub_links {
                max-height: 100vh;
                display: block;
                transition: max-height .5s linear;

                .sub_link_container {
                    height: auto;
                    animation-name: sidebar_sub_link-openAnimation;
                    opacity: 1;

                }

            }

            .sub_link.active {

                background-color: var(--canvas-color);
                box-shadow: var(--component-box-shadow);

                svg {
                    fill: var(--primary-text-color);
                }

                .name {
                    color: var(--primary-text-color);
                }
            }

            .sub_links::before {
                content: '';
                position: absolute;
                left: 24px;
                width: 2px;
                background-color: var(--accent-5);
                opacity: .5;
                max-height: 100vh;
                height: 82%;
                top: 9%;

            }

            @for $i from 1 through 20 {
                .sub_links .sub_link_container:nth-child(#{$i}n) {
                    animation-duration: #{$i * 0.7}s;

                }
            }


        }
    }

    .nav_profile_container {
        border-top: var(--border-default);
        display: flex;

        .nav_profile {
            flex: 1 1 0;
            margin: 8px;
            display: flex;
            gap: 14px;
            align-items: center;

            .profile_img {
                img {
                    border-radius: 24px;
                    max-width: 40px;
                    width: 40px;
                    max-height: 40px;
                    height: 40px;
                    object-fit: cover;
                    cursor: pointer;
                }

            }

            .profile_info {
                flex: 1 1 0;
                word-break: keep-all;
                white-space: nowrap;

                .username {
                    cursor: pointer;
                }

                .role {
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--accent-4);
                }
            }

            .logout {
                cursor: pointer;

                svg {

                    fill: var(--accent-3);
                }

            }
        }
    }


}

.nav_container.open {
    max-width: 240px;
    transition: max-width 0.3s;
    min-width: 240px;
}

.nav_container.close {
    max-width: 56px;
    min-width: 56px;
    transition: max-width 0.3s;

    .nav_logo {

        display: flex;

        .logo {
            display: none;
        }

        .caret {

            display: none;
        }
    }

    .search_container {

        .search {
            .search_input {
                display: none;
            }
        }
    }

    .nav_link_container {
        .nav_link {
            gap: 0;


            .name {
                display: none;
            }

            .icon {}
        }

        .sub_links {
            display: none;
            border-radius: 4px;
            height: 0px;
            opacity: 0;

            .sub_link_container {
                display: none;

                .sub_link {
                    margin: 2px 12px;
                }
            }
        }

        .sub_links::before {
            display: none;
        }

    }

    .nav_link_container:hover .sub_links {
        position: absolute !important;
        padding: 8px 0;
        left: 56px;
        display: block;
        background-color: var(--component-color);
        opacity: 1;
        max-height: none;
        height: auto !important;
        z-index: 100;

        .sub_link_container {
            display: block;
            opacity: 1;
            height: auto;
        }
    }


    .nav_profile {
        .profile_info {
            display: none;
        }

        .logout {
            display: none;
        }
    }
}