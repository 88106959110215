.description {
  .type {
    padding: 1px 3px 2px 3px;
    background-color: var(--color-b4);
    border-radius: 4px;
    margin-right: 4px;
    color: var(--accent-6);
  }

  flex: 1 1 0;
  line-height: 18px;

  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.icon {
  padding: 6px;
  cursor: pointer;
  color: var(--accent-3);
  svg {
    fill: var(--accent-3);
  }
}
