.timetable {
    height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;

    .body {
        display: grid;
        background-color: rgb(235, 235, 235);
        height: 100%;

        .timetable_block {
            background-color: rgb(255, 255, 255);
            display: flex;
            flex-direction: column;
            font-family: 'Pretendard';
            font-weight: 600;
            overflow: hidden;
            cursor: pointer;

            .title {
                padding: 4px 0 0 4px;

            }

            .subtitle {
                font-size: 12px;
                padding: 4px 0 0 4px;


                
            }
        }
    }

}



.row_header {
    display: flex;

    .item {
        flex: 1 1 0;
        padding-bottom: 4px;
        font-size: 14px;
        font-family: 'Pretendard';
        font-weight: 500;
    }

}