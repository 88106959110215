.section {

    margin: 24px;

    .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
        color: var(--accent-1);


    }

    .description {
        font-size: 13px;
        font-family: 'Pretendard';
        font-weight: 500;
        color: var(--accent-3);
    }


    .popup {
        .title {
            margin-top: 12px;
            font-size: 14px;
            font-weight: 600;
        }

        .row {
            margin-top: 12px;
            display: flex;
            gap: 12px;
        }


    }

    .form {
        display: flex;

        .item {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;

            .title {
                margin: 12px;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
            }

            .label {
                font-size: 14px;
                font-weight: 500;
                padding: 12px;
                border-radius: 4px;
                border: var(--border-default);
                background-color: var(--component-color);
            }
        }
    }
}
.search_container {
    height: 56px;
    display: flex;
    border-bottom: var(--border-default);
    align-items: center;

    .title {
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 0 24px;
        color: var(--accent-1);

    }

    .search {
        margin: 0 24px;
        outline: none;
        border: none;
        border-radius: 8px;
        background-color: var(--component-color);
        padding: 8px 18px;
        flex: 1 1 0;
    }
}

.section {

    height: calc(100vh - 56px);
    overflow: auto;

    .settings_container {

        padding: 24px;
        background-color: var(--background-color);
        border-radius: 8px;

        max-width: 1200px;
        margin: 0 auto;

        .container_title {
            font-size: 16px;
            color: var(--accent-2);
            font-weight: 600;
            margin-bottom: 24px;
        }
    }

    .setting_item {
        margin: 16px 0;
        display: flex;
        .icon{
            fill: var(--accent-1);
            width: 40px;
            display: flex;
            align-items: center;
        }

        .info {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;

            .label {
                font-size: 13px;
                color: var(--accent-2);
                font-weight: 600;
                margin-bottom: 5px;

            }

            .description {
                font-size: 12px;
                color: var(--accent-4);
                font-weight: 600;
                margin-bottom: 5px;
            }
        }

        .controls {
            fill: var(--accent-1);
            display: flex;
            align-items: flex-end;
        }

    }

}