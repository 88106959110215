.section {
	padding: 24px;
	height: calc(100% - 56px);
	overflow: scroll;

	.container {
		max-width: 1000px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		gap: 12px;

		.user_not_found_msg {
			margin-top: 60px;
			text-align: center;
			color: var(--accent-3);
		}
	}
}