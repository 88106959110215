.viewer {
  .days {
    font-weight: 500;
    display: flex;
    font-size: 12px;
    background-color: white;
    z-index: 12;
    position: sticky;
    top: 0;

    .day {
      flex: 1 1 0;
      text-align: center;
      padding: 6px 0px;
      border-right: var(--border-default);
      border-right-width: 1px;
      border-bottom: var(--border-default);
      border-bottom-width: 1px;
    }

    .day:last-child {
      border-right: none;
    }
  }

  .cells {
    font-size: 12px;
    aspect-ratio: 1.5;

    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    justify-items: center;

    .cell {
      width: 100%;
      height: 100%;
      border: var(--border-default);
      border-top: none;
      border-left: none;

      .date {
        transition: background-color 0.1s ease;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 24px;
        width: 24px;
        justify-content: center;
        border-radius: 24px;
      }

      .date:hover {
        background-color: var(--component-hover-color);
      }

      .date.today {
        background-color: #007affe0;
        color: var(--accent-6);
      }

      .itemList {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding: 0px 2px;

        .item {
          border-radius: 4px;
          padding: 2px;
        }

        .item:hover {
          background-color: var(--component-hover-color);
        }
      }
    }
    .current {
      background-color: white;
    }

    .cell:nth-child(7n) {
      border-right: none;
    }

    .cell:nth-last-child(-n + 7) {
      border-bottom: none;
    }
  }
}
