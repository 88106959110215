.btn {
    cursor: pointer;
    color: var(--btn-text-color-1);

    background-color: var(--btn-color-1);
    text-align: center;
    display: flex;
    height: 42px;
    padding: 0 6px;
    justify-content: center;
    align-items: center;

    font-size: 0.875rem;

    //un selectable
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
     supported by Chrome, Edge, Opera and Firefox */

}


.round {
    border-radius: 40px;
}

.hover {
    background-color: transparent;
    color: var(--accent-1);

}

.hover:hover {
    background-color: var(--background-hover-color);

}


.ghost {
    border: var(--border-default);
    background-color: transparent;
    color: var(--accent-1);
    border-radius: 4px;
    height: 32px;
}

.ghost:hover {
    background-color: var(--background-hover-color);
}

.smooth {
    background-color: rgba(var(--btn-color-1), 0.2);
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}