.select {
    cursor: pointer;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;




    .label {
        font-size: 12px;
        font-family: 'Pretendard';
        font-weight: 500;
        margin-bottom: 8px;
        color: var(--accent-1);

        .required {
            padding-left: 1px;
            color: var(--alert-c1);
        }
    }

    .selected {
        position: relative;
        border: var(--border-default);
        max-height: 30px;
        min-height: 30px;
        display: flex;
        align-items: center;
        padding: 8.5px 14px;

        .text {
            flex: 1 1 0;
            padding-right: 8.5px;
            outline: none;
            border: none;

            white-space: pre;
            color: var(--accent-1);
        }

        .icon {
            fill: var(--accent-1);
        }


    }

    .options {
        border: var(--border-default);
        border-top: none;
        cursor: pointer;
        width: calc(100% + 2px);
        z-index: 2022;
        position: absolute;

        top: 29px;
        left: -1px;

        overflow: auto;
        max-height: 165px;

        background-color: var(--component-color);


        .option {
            height: 30px;
            display: flex;
            padding: 0 14px;
            white-space: nowrap;
            align-items: center;
            color: var(--accent-1);

        }

        .option:hover {

            background-color: var(--component-hover-color);

        }
    }
}

.select.flat {


    .label {
        font-size: 12px;
        color: var(--accent-2);
        font-weight: 600;
        margin-bottom: 8px;

        .required {
            padding-left: 1px;
            color: var(--alert-c1);
        }

    }

    .selected {
        max-height: 30px;
        min-height: 30px;
        display: flex;
        justify-content: center;
        outline: none;
        border: none;
        border-radius: 8px;
        background-color: var(--component-color);
        padding: 0 14px;
        width: 100%;

        .text {
            flex: 1 1 0;
            padding-right: 8.5px;
            outline: none;
            border: none;
            color: var(--accent-1);
        }

        .icon {
            fill: var(--accent-1);
        }


    }

    .options {
        border: none;
        width: 100%;
        border-radius: 8px;
        cursor: pointer;
        width: 100%;
        z-index: 2022;
        position: absolute;

        top: 36px;
        left: 0px;


        background-color: var(--component-color);


        .option {
            border-radius: 8px;
            height: 30px;
            display: flex;
            padding: 0 14px;
            white-space: nowrap;
            align-items: center;
            color: var(--accent-1);

        }

        .option:hover {

            background-color: var(--component-hover-color);

        }
    }

}