.section {

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 1;
    background: var(--component-color);
    left: 0;
    top: 0;


    .container {
        background: var(--background-color);
        box-shadow: var(--component-box-shadow);
        border-radius: 8px;
        max-width: 420px;
        width: 100%;
        padding: 24px;
        margin: 24px;

        display: flex;
        border-radius: 4px;
        flex-direction: column;
        justify-content: center;

        .title {
            text-align: center;
            font-size: 20px;
            margin-bottom: 12px;
            color: var(--accent-1);
            font-weight: 600;

        }
        .subtitle {
            text-align: center;
            font-size: 12px;
            margin-bottom: 12px;
            color: var(--accent-1);
            font-weight: 300;
        }

    }

    .container_logo {
        width: 100%;
        padding: 24px;
        
        display: flex;
        justify-content: center; /* 수평 중앙 정렬 */
        align-items: center;     /* 수직 중앙 정렬 */
        .title {
            text-align: center;
            font-size: 30px;
            color: var(--accent-1);
            font-weight: 600;
        }
        .logo {
            cursor: pointer;
            text-align: center;
        }
    }
}