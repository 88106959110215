.section::-webkit-scrollbar {
  display: none;
}

.section {
  height: calc(100vh - 56px);
  padding: 24px;
  overflow-y: auto;
  color: var(--accent-1);

  -ms-overflow-style: none;
  scrollbar-width: none;

  .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
    color: var(--accent-1);
  }

  .description {
    font-size: 13px;
    font-family: "Pretendard";
    font-weight: 500;
    color: var(--accent-3);
  }

  .search {
    display: flex;
    align-items: center;
    gap: 24px;
    height: 32px;
    margin: 24px 0;

    .label {
      font-weight: 600;
      font-size: 14px;
      word-break: keep-all;
    }
  }

  .content {
    margin-top: 24px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    border-top: var(--border-default);

    .field {
      display: flex;
      border-bottom: var(--border-default);
      border-right: var(--border-default);

      .label {
        border-right: var(--border-default);
        border-left: var(--border-default);
        flex-basis: 100px;
        padding: 6px 12px;
        background-color: var(--component-color);
      }

      .input {
        outline: none;
        border: none;
        padding: 6px 12px;
        color: var(--accent-1);

        flex: 1 1 0;
        background: var(--background);
      }
    }
  }

  .categories_container {
    overflow: scroll auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .categories_container::-webkit-scrollbar {
    display: none;
  }

  .categories {
    display: flex;
    gap: 4px;
  }

  .category {
    cursor: pointer;
    border: var(--border-default);
    padding: 10px 14px;
    font-size: 14px;
    font-family: Pretendard;
    font-weight: 500;
    white-space: nowrap;
  }

  .category:hover {
    background-color: #ebebeb;
  }
}
