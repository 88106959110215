.section::-webkit-scrollbar {
  display: none;
}

.section {
  height: calc(100vh - 56px);
  padding: 24px;
  overflow-y: auto;
  color: var(--accent-1);

  -ms-overflow-style: none;
  scrollbar-width: none;

    .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 24px;
        color: var(--accent-1);

    }

  .categories_container {
    overflow: scroll auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .categories_container::-webkit-scrollbar {
    display: none;
  }

  .categories {
    display: flex;
    gap: 4px;
  }

  .category {
    cursor: pointer;
    border: var(--border-default);
    padding: 10px 14px;
    font-size: 14px;
    font-family: Pretendard;
    font-weight: 500;
    white-space: nowrap;
  }

  .category:hover {
    background-color: #ebebeb;
  }
}